<!--
 * @Author: jiang
 * @Date: 2021-06-12 09:55:26
 * @Description: 树型结构
-->
<template>
  <div class="tree-box">
    <div
      class="btn-box-top"
      v-if="manager"
    >
      <el-button
        class="add-btn"
        type="primary"
        @click="onCommand('add', {})"
      >{{ createText }}</el-button>
    </div>
    <el-tree
      ref="tree"
      :data="data"
      :props="props"
      :node-key="nodeKey"
      highlight-current
      :expand-on-click-node="false"
      :default-expand-all="defaultExpandAll"
      :current-node-key="current"
      @current-change="onCategoryChange"
    >
      <template slot-scope="{ data }">
        <div class="tree-item">
          <span>{{ data.name }} </span>
          <el-dropdown
            v-if="data.user_id==userId||manager "
            class="opt-btn"
            @command="onCommand($event, data)"
          >
            <i
              class="el-icon-more-outline"
              style="transform: rotate(90deg);"
              @click.stop
            ></i>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="add">
                <i class="el-icon-plus"></i>
                <span>添加</span>
              </el-dropdown-item>
              <el-dropdown-item command="edit">
                <i class="el-icon-edit"></i>
                <span>编辑</span>
              </el-dropdown-item>
              <el-dropdown-item command="delete">
                <i class="el-icon-delete"></i>
                <span>删除</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>
    </el-tree>

    <el-dialog
      :title="title"
      :visible.sync="visible"
      destroy-on-close
      width="600px"
    >
      <b-form
        ref="form"
        :option="formOption"
        :data="formData"
      >
      </b-form>
      <template slot="footer">
        <el-button @click="visible=false">取消</el-button>
        <b-btn-confirm
          :loading="submitLoading"
          @click="onSubmit"
        ></b-btn-confirm>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    data: {},
    props: {
      type: Object,
      default: () => ({ label: 'name' }),
    },
    defaultExpandAl:true,
    nodeKey: {
      type: String,
      default: 'id',
    },
    current: {
      type: [String, Number],
    },
    manager: {
      type: Boolean,
      default: true,
    },
    submitLoading: {
      type: Boolean,
    },
    formOption: {
      type: Object,
    },
    createText: {
      type: String,
      default: '新增分类',
    },
  },
  data() {
    return {
      visible: false,
      formData: {},
      currentItem: {},
      currentType: null,
      user_id: this.$store.state.user.userInfo.id,
    }
  },
  watch: {
    current: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.$refs.tree.setCurrentKey(val)
            const data = this.data.find(item => item.id === val)
            if (data) {
              this.$emit('change', data)
            }
          })
        }
      },
      immediate: true,
    },
    data: {
      handler() {
        this.handlerGetName()
      },
    },
  },
  computed: {
    userId() {
      return this.$store.getters['user/id']
    },
  },
  methods: {
    getName(id) {
      return new Promise(resolve => {
        this.resolve = resolve
        this.nameId = id
        this.handlerGetName()
      })
    },
    handlerGetName() {
      function treeFind(tree, func) {
        for (const node of tree) {
          if (func(node)) return node
          if (node.children) {
            const res = treeFind(node.children, func)
            if (res) return res
          }
        }
        return null
      }
      const nameId = this.nameId
      const tree = this.data
      if (tree.length && nameId) {
        const node = treeFind(tree, node => node.id === nameId)
        this.nameId = ''
        this.resolve(node.name)
      }
    },
    onCategoryChange(val) {
      this.$emit('change', val)
    },
    onCommand(command, data) {
      const formData = {}
      switch (command) {
        case 'add':
          this.visible = true
          this.formOption.columns.forEach(item => {
            formData[item.prop] = ''
          })
          this.formData = formData
          this.currentType = 'add'
          this.currentItem = data
          break
        case 'edit':
          this.visible = true
          this.formOption.columns.forEach(item => {
            formData[item.prop] = data[item.prop]
          })
          this.formData = formData
          this.currentType = 'edit'
          this.currentItem = data
          break
        case 'delete':
          this.$emit('delete', data)
          break
        default:
          console.log('error')
      }
    },
    onSubmit() {
      this.$refs.form
        .valid()
        .then(data => {
          if (this.currentType === 'add') {
            this.$emit('add', { tree: this.currentItem, data })
          } else if (this.currentType === 'edit') {
            this.$emit('edit', { tree: this.currentItem, data })
          }
        })
        .catch(() => {})
    },
    closeDialog() {
      this.visible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.tree-box {
  position: relative;

  .tree-item {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;

    .opt-btn {
      display: none;
    }

    &:hover .opt-btn {
      display: block;
    }
  }

  .btn-box-top {
    padding: 10px;
    padding-right: 10px;
    // text-align: center;

    .add-btn {
      background-color: #36cfc9;
      border-color: #36cfc9;
    }
  }

  .btn {
    padding: 3px;
  }

  /deep/ .el-tree-node__content {
    height: 34px;
  }
}
</style>
