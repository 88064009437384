<!--
 * @Author: jiang
 * @Date: 2021-06-11 16:12:55
 * @Description: 任务落实
-->
<template>
  <div class="task-container g-page-list">
    <!-- 侧边栏 -->
 <!--   <el-scrollbar y class="g-bg g-mr-10" style="width: 240px;">
      <catelog-view @change="onCategoryChange"></catelog-view>
    </el-scrollbar -->

    <!-- 主内容区 -->
    <el-scrollbar y class="g-bg g-flex-fill">
      <div class="g-p-20">
        <el-form ref="form" :model="searchData" inline label-position="top">
          <el-form-item label="传阅说明" prop="name">
            <el-input v-model="searchData.name" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="批复时限" prop="stop_date" >
            <el-date-picker
              v-model="searchData.stop_date"
              type="daterange"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              clearable
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item style="vertical-align: bottom;">
            <c-btn-search
              :show="['search', 'reset', { id: 'create', text: '发起文件传阅' }]"
              @search="onRefresh"
              @reset="onReset"
              @create="onAdd"
            ></c-btn-search>
          </el-form-item>
        </el-form>
        <el-table :data="list" v-loading="tableLoading">
          <el-table-column label="ID" prop="id" width="60" />
          <el-table-column label="传阅说明" prop="name" show-overflow-tooltip />
          <el-table-column label="发起人" prop="user.name" width="100" />
          <!-- <el-table-column label="落实部门" show-overflow-tooltip>
            <template slot-scope="{ row }">
              <div>
                <el-tag v-for="item in handlerDept(row)" :key="item.id" :type="item.done ? 'success' : 'danger'">{{
                  item.name
                }}</el-tag>
              </div>
            </template>
          </el-table-column> -->
          <el-table-column label="收文人" show-overflow-tooltip>
            <template slot-scope="{ row }">
              <div>
                <el-tag v-for="item in handlerUser(row)" :key="item.id" :type="item.done ? 'success' : 'danger'">{{
                  item.name
                }}</el-tag>
              </div>
            </template>
          </el-table-column>
         <!-- <el-table-column label="状态" prop="state" width="80" /> -->
          <el-table-column label="回复时限" prop="executive_end_data" width="140" />
          <el-table-column label="操作" width="180" align="center">
            <template slot-scope="{ row }">
              <c-btn-table
                :show="['info', 'update', 'delete']"
                @info="onView(row)"
                @update="onUpdate(row)"
                @delete="onDelete(row)"
              ></c-btn-table>
            </template>
          </el-table-column>
        </el-table>

        <b-pagination :page="page" @change="fetchList"></b-pagination>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import CatelogView from '@/components/tree/task-catelog'
import { getTaskPage, deleteTask } from '@/api/task'

export default {
  components: {
    CatelogView,
  },
  data() {
    return {
      currentCatelog: 132,
      tableLoading: false,
      page: {
        current: 1,
        total: 0,
        size: 15,
      },
      searchData: {
        name: '',
        create_date: [],
        stop_date: [],
      },
      list: [],
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    fetchList() {
      this.tableLoading = true
      const params = {
        page: this.page.current,
        name: this.searchData.name,
        meeting_id: this.currentCatelog,
        create_start_date: this.searchData.create_date[0],
        create_end_date: this.searchData.create_date[1],
        stop_start_date: this.searchData.stop_date[0],
        stop_end_date: this.searchData.stop_date[1],
      }
      getTaskPage(params)
        .then(res => {
          this.list = res.data
          this.page.total = res.total
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    onRefresh() {
      this.page.current = 1
      this.fetchList()
    },
    onReset() {
      this.$refs.form.resetFields()
      this.onRefresh()
    },
    onCategoryChange(id) {
      this.currentCatelog = id
      this.fetchList()
    },
    onAdd() {
      this.$router.push({
        path: '/task_file/create',
        query: { cate: 132 },
      })
    },
    onUpdate(row) {
      this.$router.push({
        path: '/task_file/update',
        query: { id: row.id },
      })
    },
    onDelete(row) {
      this.$confirm('确定要删除当前文件传阅任务吗', '提示', { type: 'warning' })
        .then(() => {
          return deleteTask(row.id)
        })
        .then(() => {
          this.$message('文件传阅任务成功删除')
          this.onRefresh()
        })
        .catch(() => {})
    },
    onView(row) {
      this.$router.push({
        path: '/task_file/info',
        query: { id: row.id, type: 'task' },
      })
    },
    handlerDept(row) {
      const depts = []
      row.executive_departments.forEach(item => {
        depts.push({
          id: item.id,
          name: item.name,
          done: row.tasks.find(it => it.taskable_id === item.id)?.actions.length > 0,
        })
      })
      return depts
    },
    handlerUser(row) {
      const users = []
      row.executive_users_data.forEach(item => {
        users.push({
          id: item.id,
          name: item.name,
          done: row.tasks.find(it => it.taskable_id === item.id).actions.length > 0,
        })
      })

      return users
    },
  },
}
</script>

<style lang="scss" scoped>
.task-container {
  display: flex;
  height: 100%;
  padding: 0 15px 15px;
}
</style>
